import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledTextBlock = styled.div`
  margin-top: 1em;
  p {
    margin-bottom: 0.75rem;
  }
  h4,h5,h6 {
    margin-top:2rem;
    margin-bottom:1rem;
  }  
`

class TextBlock extends Component {
  render() {
    const { html } = this.props
    return <StyledTextBlock dangerouslySetInnerHTML={{  __html: html }} />
  }
}

export default TextBlock

TextBlock.propTypes = {
  html: PropTypes.node.isRequired,
}
