import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, Profile, Wrapper, Title, SubTitle, TextBlock, About, Works, LinkButton } from '../components'
import website from '../../config/website'
import Helmet from 'react-helmet'
import Logo from '../assets/logo.svg'
import Penguins from '../assets/penguins.svg'


const Hero = styled.header`
  background-color: ${(props) => props.theme.colors.main};
  display: flex;
  align-items: center;
`

const HeroInner = styled(Wrapper)`
  padding-top: 6rem;
  padding-bottom: 8rem;
  h1 {
    text-align: center;
    font-style: normal;
    color: ${(props) => props.theme.colors.greyDark};
    margin: 1.5rem 0 0;
    font-size: 1.75rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    padding-top: 8rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
    h1 {
      margin: 1rem 0 0;
      font-size: 1.25rem !important;
      font-style: noraml;
    }
  }
`
const HeroImage = styled.div`
  text-align: center;
  margin-top: 1.25rem;
  svg {
    fill: ${(props) => props.theme.colors.greyDark};
    width: 30%;
  }
  `

const HeroLogo = styled.div`
  margin-top: 2rem;
  text-align: center;
  svg {
    fill: ${(props) => props.theme.colors.greyDark};
    width: 55vw;
    padding-left: 2.5vw;
    max-width: 800px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    svg {
      padding-left: 5vw;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    svg {
      padding-left: 5vw;
      width: 60vw;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: 1.5rem;
    svg {
      padding-left: 7.5vw;
      width: 80vw;
    }
  }
`

const HeroText = styled.div`
  font-size: 1rem;
  line-height: 1.75;
  margin-top: .75rem;
  text-align: center;
  p {
    margin-bottom: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {

  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: .5rem;
    font-size: 0.75rem;
  }
`

const Social = styled.div`
  h4 {
    margin-top:2rem;
    margin-bottom:1rem;
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 1rem;
    li {
      display: inline;
      margin: 0 2.5rem .75rem 0;
      @media (max-width: ${(props) => props.theme.breakpoints.s}) {
        width: calc(50% - 2.5rem);
      }
      a {
        font-style: normal;
        text-decoration: underline;
        color: ${(props) => props.theme.colors.blue};
        font-weight: 600;
        &:hover,
        &:focus {
          color: ${(props) => props.theme.colors.primary};
        }
        @media (max-width: ${(props) => props.theme.breakpoints.s}) {
        }
      }
    }
  }
`



const IndexWrapper = styled.main`
  background-color: ${(props) => props.theme.colors.bg};
`

class Index extends Component {
  render() {
    const {
      data: { homepage, social, about, works , profile },
    } = this.props
    return (
      <Layout>
        <Helmet>
          <script>
          {`
          (function(d) {
            var config = {
              kitId: 'ycj4esw',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
          })(document);
          `}
          </script>        
        </Helmet>
        <Hero>
          <HeroInner>
            <HeroImage>
              <Penguins />
            </HeroImage>
            <HeroLogo>
              <Logo />
            </HeroLogo>
            <h1>{homepage.data.title.text}</h1>
            <HeroText dangerouslySetInnerHTML={{ __html: homepage.data.content.html }} />
          </HeroInner>
        </Hero>


        <IndexWrapper>
          <Wrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
              <Title>Profile</Title>
              <Profile props={profile} />

              <Title>About Me</Title>
              <About props={about} />

              <Social>
                  <h4>Social</h4>
                  <ul>
                  {social.nodes.map((s, index) => (
                    <li data-name={`social-entry-${index}`} key={s.primary.label.text}>
                      <a href={s.primary.link.url} target={'_blank'}>{s.primary.label.text}</a>
                    </li>
                  ))}
                  </ul>
              </Social>

              <Title id={'works'}>Works</Title>
              <Works props={works} />

              <Title>Contact</Title>
              <div style={{ marginTop: '2.5rem'}}>
                  <p>お仕事のご相談などは、こちらからご連絡くださいませ。</p>
                  <LinkButton href={'https://forms.gle/mDpL9UWrga2GAnHq6'} target={'_blank'}>お問い合わせ</LinkButton>
              </div>
          </Wrapper>
        </IndexWrapper>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      }),
    }),
    social: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    profile: allPrismicProfileBodyListItem {
      nodes {
        primary {
          label {
            text
          }
          content {
            html
          }
        }
      }
    }
    social: allPrismicSocialBodyLinkItem {
      nodes {
        primary {
          label {
            text
          }
          link {
            url
          }
        }
      }
    }
    about: prismicAbout {
      data {
        title {
          text
        }
        name {
          text
        }
        name_en {
          text
        }
        content {
          html
        }
      }
    }
    works: allPrismicWorks(sort: { fields: [data___priority], order: ASC }) {
      nodes {
        data {          
          title {
            text
          }
          body {
            ...on PrismicWorksBodyWork {
              items {
                work_title {
                  text
                }
                work_charge {
                  text
                }
                work_start(formatString: "YYYY.MM")
                work_end(formatString: "YYYY.MM")
                work_description {
                  html
                }
              }
            }      
          }
        }
      }
    }
  }
`
