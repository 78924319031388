import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledFooter = styled.footer`
  background-color: #fff;
  > div {
    max-width: ${(props) => props.theme.maxWidth};
    margin: 0 auto;
    padding: 8rem 2rem 2rem;
    text-align: center;
    font-size: .75rem;
    color: ${(props) => props.theme.colors.grey};
  }
`

class Footer extends Component {
  render() {
    const { children } = this.props
    return <StyledFooter><div>{children}</div></StyledFooter>
  }
}

export default Footer

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}
