import styled from '@emotion/styled'

const Title = styled.h2`
  margin-top:8rem;
  font-style: italic;
  color: ${(props) => props.theme.colors.greyDark};
  position: relative;
  &:first-of-type {
    margin-top:4rem;
  }
  &:before {
    content: '';
    width: 3rem;
    height: 1px;
    background-color: ${(props) => props.theme.colors.grey};
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -80px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}) {
    &:before {
      width: 1.5rem;
      left: -40px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}) {
    &:before {
      width: 1.5rem;
      left: -35px;
    }
  }
`

export default Title
