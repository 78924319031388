module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'パルヒコさん - モリケイタ (Webディレクター・デザイナー)', // Navigation and Site Title
  titleAlt: 'パルヒコさん モリケイタ', // Title for JSONLD
  description: 'Web/デジタル領域の制作ディレクション、デザイン、コンサルティング / パンフレット、フライヤーなどのデザイン / マンガ制作',
  headline: 'Director of Web/Digital Development, Web/Digital Designer', // Headline for schema.org JSONLD
  url: 'https://paruhiko.com', // Domain of your site. No trailing slash!
  siteLanguage: 'ja', // Language Tag on <html> element
  logo: '/logos/logo-1200.png', // Used for SEO
  ogLanguage: 'ja_JP', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'ParuhikoSan', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Keita Mori', // Author for schemaORGJSONLD
  themeColor: '#F9E700',
  backgroundColor: '#FFFFFF',

  twitter: '@kt_flcl', // Twitter Username
  facebook: 'kt.flcl', // Facebook Site Name
  googleAnalyticsID: 'UA-64862808-1',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
