import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import TextBlock from './TextBlock'
import theme from '../styles/theme'

const StyledDiv = styled.div`
  > h3 {
    margin-top: 3.5rem;
    color: ${theme.colors.greyDarker};
    font-style: normal;
    > span {
      display: block;
      margin: .5em 0 0 .25rem;
      font-size: 1rem;
      color: ${theme.colors.grey};
    }
  }
`

class About extends Component {
  render() {
    const { props } = this.props
    return(
      <StyledDiv>
        <h3>
          {props.data.name.text}
          <span>{props.data.name_en.text}</span>
        </h3>
        <TextBlock html={props.data.content.html} />      
      </StyledDiv>
    )
  }
}

export default About

About.propTypes = {
  props: PropTypes.object.isRequired,
}
