import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import TextBlock from './TextBlock'
import theme from '../styles/theme'

const StyledListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 3.5rem;
  > li {
    margin-top: 7rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`
const StyledWorkListing = styled.ul`
  list-style-type: none;
  margin-left: 0.25rem;
  margin-top: 2.5rem;
  > li {
    padding-left: 1.5rem;
    border-left: 2px solid ${theme.colors.main};
    margin-top: 3.5rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`
const StyledWorkDate= styled.p`
  font-size: 0.75rem;  
  margin-bottom: 0.5rem;
  color: ${theme.colors.greyDark};
`

const StyledWorkTitle = styled.h4`
  font-style : normal;
  font-size: 1.33rem;
  margin-bottom: 0.5rem;
  line-height: 1.33;
`

const StyledWorkCharge= styled.p`
  font-size: .75rem;  
  margin-bottom: 1rem;
  color: ${theme.colors.greyDarker};
`



class Works extends Component {
  render() {
    const { props } = this.props
    return (
        <StyledListing>
        {props.nodes.map((ws, ws_index) => (
            <li data-name={`works-entry-${ws_index}`} key={ws.data.title.text}>
              <h3>{ws.data.title.text}</h3>
              <StyledWorkListing>
                {ws.data.body[0].items.reverse().map((w, w_index) => (
                <li data-name={`works-entry-${ws_index}-${w_index}`} key={w.work_title.text}> 
                    {w.work_start ? (
                      <StyledWorkDate>{w.work_start} 〜 {w.work_end}</StyledWorkDate>
                    ) : (
                      <StyledWorkDate>{w.work_end}</StyledWorkDate>
                    )}                  
                  <StyledWorkTitle>{w.work_title.text}</StyledWorkTitle>
                  <StyledWorkCharge>{w.work_charge.text}</StyledWorkCharge>
                  <TextBlock html={w.work_description.html } />
                </li>
              ))}
              </StyledWorkListing>
            </li>
        ))}
      </StyledListing>
    )
  }
}

export default Works

Works.propTypes = {
  props: PropTypes.object.isRequired,
}
