import styled from '@emotion/styled'

const SubTitle = styled.h3`
  margin-top:2rem;
  font-style: normal;
  position: relative;
  &:first-of-type{
    margin-top:0rem;
  }
`

export default SubTitle
