const theme = {
  colors: {
    primary: '#a2bce2',
    bg: '#fff',
    main: '#F9E700',
    greyDark: '#636458',

    black: '#231815',
    greyLight: '#b1b2ac',
    greyBlue: '#a2bce2',
    grey: '#8a8b82',
    greyDarker: '#2d231f',
  },
  maxWidth: '900px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '400px',
    s: '768px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
