import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import TextBlock from './TextBlock'

const StyledListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 3.5rem;
  li {
    margin-top: 2.5rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`
const StyledHeading = styled.h4`
  margin-bottom: 0;
`

class Profile extends Component {
  render() {
    const { props } = this.props
    return (
        <StyledListing>
        {props.nodes.map((p,index) => (
          <li data-name={`profile-entry-${index}`} key={p.primary.label.text}>
            <StyledHeading>{p.primary.label.text}</StyledHeading>
            <TextBlock html={p.primary.content.html} />
          </li>
        ))}
      </StyledListing>
    )
  }
}

export default Profile

Profile.propTypes = {
  props: PropTypes.object.isRequired,
}
