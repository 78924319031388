import styled from '@emotion/styled'
import theme from '../styles/theme'

const LinkButton = styled.a`
  display: inline-block;

  background-color: ${(props) => props.theme.colors.main};
  color: ${(props) => props.theme.colors.greyDarker};
  opacity:1;
  font-style: normal;
  font-weight: bold;
  font-size: 1.33rem;
  text-align: center;

  box-sizing: border-box;
  width: 100%;

  margin-top: .5em;
  padding: 0.75rem 4rem;
  border-radius: 1rem;
  text-decoration: none !important;

  &:hover {
    opacity:.6;
    text-decoration:none;
  }
 `

export default LinkButton
